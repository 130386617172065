<template>
  <div id="intro">
    <div class="title">
      <h3>KOREA TEA CULTURE ASSOCIATION</h3>
      <h2 v-if="!props.mobile">
        마운 다미원은 <span>올바른 차</span> 문화를 보급<br />연구하는
        차문화기관입니다.
      </h2>
      <h2 v-else>
        마운 다미원은<br /><span>올바른 차</span> 문화를<br />보급, 연구하는<br />차문화기관입니다.
      </h2>
    </div>
    <div class="d_flex_center">
      <!-- txt -->
      <TxtList :list="list" />
      <!-- icon -->
      <div
        v-if="!props.mobile"
        class="ico_fan"
        data-aos-anchor="#intro"
        data-aos="fade-down"
        data-aos-easing="ease-in-sine"
        data-aos-delay="2000"
        data-aos-duration="1000"
        data-aos-once="false"
      >
        <div class="ico fan">
          <img src="@/assets/images/ico_fan.svg" alt="fan-icon" />
        </div>
      </div>
      <!-- img -->
      <div class="img_wrap">
        <picture>
          <source
            srcset="@/assets/images/section2_img_mo.png"
            alt="section2-image"
            media="(max-width: 600px)"
          />
          <img src="@/assets/images/section2_img.png" alt="section2-image" />
        </picture>
      </div>
    </div>
  </div>
</template>

<script setup>
import TxtList from "../components/txt-list.vue";
import { ref, defineProps, onMounted } from "vue";

const list = ref({
  title: "차(茶)문화의 연구 계승 발전 차(茶)와 인성예절교육",
  p1: "마운다미원에서는 차문화 보급, 차 관련 제반 분야를",
  p2: "연구하며 우리가 갖추어야 할 덕목과",
  p3: "기본 정신을 배우고 익히며",
  p4: "차 문화의 우수성을 널리 알라고자 노력합니다.",
});

const props = defineProps({ mobile: Boolean });

// animation
const setAOSAttr = () => {
  if (!props.mobile) {
    const title = document.querySelector(".title");
    const txt = document.querySelector(".flex1");
    const img = document.querySelector(".img_wrap");

    const scroll = window.scrollY;

    title.dataset.aos = scroll > 200 ? "fade-down" : "fade-up";
    title.dataset.aosDelay = scroll > 200 ? "1000" : "0";
    title.dataset.aosDuration = scroll > 200 ? "2000" : "500";
    title.style.opacity = scroll > 200 ? "1" : "0";
    title.style.transform =
      scroll > 200 ? "translateZ(0)" : "translate3d(0, -50px, 0)";

    const height = title.offsetHeight;

    txt.dataset.aos = scroll > height ? "fade-right" : "fade-left";
    txt.dataset.aosDelay = scroll > height ? "1000" : "0";
    txt.dataset.aosDuration = scroll > height ? "1000" : "500";
    txt.style.opacity = scroll > height ? "1" : "0";
    txt.style.transform =
      scroll > height ? "translateZ(0)" : "translate3d(-100px, 0, 0)";

    img.dataset.aos = scroll > height * 1.5 ? "fade-left" : "fade-right";
    img.dataset.aosDelay = scroll > height * 1.5 ? "3000" : "0";
    img.dataset.aosDuration = scroll > height * 1.5 ? "1500" : "0";
    img.style.opacity = scroll > height * 1.5 ? "1" : "0";
    img.style.transform =
      scroll > height * 1.5 ? "translateZ(0)" : "translate3d(100px, 0, 0)";
  } else {
    return;
  }
};

onMounted(() => {
  window.addEventListener("scroll", setAOSAttr, true);
});
</script>

<style lang="scss" scoped>
#intro {
  width: 1280px;
  max-height: max-content;
  margin: 0 auto;
  position: relative;
}
.title {
  padding: 120px 0 270px;
  text-align: center;
  max-width: 810px;
  margin: 0 auto;
  &::after {
    content: "";
    display: block;
    height: 110px;
    width: 1px;
    background-color: #cbcbcb;
    position: absolute;
    left: 50%;
    top: 370px;
    transform: translateX(-50%);
  }
}
h3 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
}
h2 {
  font-size: 52px;
  line-height: 63px;
  letter-spacing: -2px;
  padding-top: 34px;
  position: relative;
  z-index: 10;
}
.green_bg {
  &::after {
    content: "";
    display: inline-block;
    width: 217px;
    height: 60px;
    background: #a0e494;
    position: absolute;
    top: 23%;
    z-index: -1;
    animation: slideIn 1s ease forwards;
  }
}
.ico_fan {
  position: absolute;
  width: 114px;
  height: 114px;
  left: 440px;
  bottom: 30px;
}
.img_wrap {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
}

@keyframes slideIn {
  0% {
    width: 0;
    left: 40%;
    transform: translateX(-40%);
  }
  100% {
    width: 217px;
    left: 54%;
    transform: translateX(-54%);
  }
}

@media (max-width: 1023px) {
  #intro {
    width: 100vw;
  }
  .title {
    min-width: 320px;
    &::after {
      top: 380px;
    }
  }
  h2 {
    font-size: 40px;
    line-height: 50px;
    padding-top: 24px;
  }
  .green_bg {
    &::after {
      width: 160px;
      height: 50px;
      top: 20%;
    }
  }
  .img_wrap {
    opacity: 1;
    width: 100%;
    height: 470px;
    transform: translateZ(0);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @keyframes slideIn {
    0% {
      width: 0;
      left: 44%;
      transform: translateX(-44%);
    }
    100% {
      width: 160px;
      left: 53%;
      transform: translateX(-53%);
    }
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .title {
    padding: 60px 16px 200px;
    &::after {
      height: 80px;
      top: 315px;
    }
  }
  h3 {
    font-size: 16px;
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
    padding-top: 24px;
  }
  .green_bg {
    position: relative;
    &::after {
      width: 125px;
      height: 38px;
      top: 0;
      left: 0;
    }
  }
  .img_wrap {
    height: max-content;
    img {
      width: auto;
      height: auto;
      margin: 0 auto;
      object-fit: cover;
    }
  }
  @keyframes slideIn {
    0% {
      width: 0;
      transform: translateZ(0);
    }
    100% {
      width: 125px;
      transform: translateX(0);
    }
  }

  .d_flex_center {
    flex-direction: column-reverse;
    padding: 0 16px;
    gap: 40px;
  }
}
</style>
