<template>
  <div id="home">
    <picture class="main_bg">
      <source
        srcset="@/assets/images/main_img_mo.png"
        alt="background-image"
        media="(max-width: 600px)"
      />
      <img src="@/assets/images/main_img.png" alt="background-image" />
    </picture>
    <div class="head_title">
      <h1>마운 다미원<br />방문을<br />환영합니다.</h1>
      <div class="ico_wrap">
        <img src="@/assets/images/ico_leaf_active.svg" alt="leaf_icon" />
        <img src="@/assets/images/ico_leaf.svg" alt="leaf_icon" />
        <img src="@/assets/images/ico_leaf.svg" alt="leaf_icon" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#home {
  width: 100vw;
  max-height: 780px;
  position: relative;
}
.main_bg {
  max-width: 1630px;
  height: auto;
  position: relative;
  img {
    margin-left: auto;
    object-fit: contain;
  }
}
.head_title {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-30%, -50%);
}
h1 {
  width: fit-content;
  font-size: 72px;
  line-height: 87px;
  color: #ffffff;
  position: static;
  letter-spacing: -2px;
}
.ico_wrap {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: fit-content;
  margin: 60px 0 0 15px;
}

@media (max-width: 1023px) {
  h1 {
    font-size: 48px;
    line-height: 56px;
  }
  .head_title {
    top: 58%;
    left: 50%;
    transform: translate(-50%, -58%);
  }
  .ico_wrap {
    gap: 15px;
    width: 35px;
    height: auto;
    margin-top: 20px;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  #home {
    position: relative;
    height: calc(100vh + 145px);
  }
  .main_bg {
    img {
      width: 100%;
      height: 100%;
      margin-top: 127px;
      object-fit: cover;
    }
  }
  .head_title {
    top: 235px;
    left: 33px;
    transform: translate(0, -145px);
    display: flex;
    flex-direction: column;
    gap: 40vh;
    width: calc(100vw - 66px);
  }
  h1 {
    font-size: 40px;
    line-height: 48px;
    width: max-content;
    opacity: 0.8;
  }
  .ico_wrap {
    width: 45px;
    height: 36px;
    gap: 16px;
    margin: 0 auto;
    justify-content: center;
  }
}
</style>
