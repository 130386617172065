<template>
  <div class="flex1">
    <SubTitle :txt="props.list.title" />
    <p>{{ props.list.p1 }}</p>
    <p>{{ props.list.p2 }}</p>
    <p>{{ props.list.p3 }}</p>
    <p>{{ props.list.p4 }}</p>
    <p>{{ props.list.p5 }}</p>
  </div>
</template>

<script setup>
import SubTitle from "./sub-title.vue";
import { defineProps } from "vue";
const props = defineProps({ txt: String, list: Object });
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 40px;
  max-width: 430px;
}
p {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -1px;
}

@media (max-width: 1023px) {
  h3 {
    max-width: 45vw;
  }
  p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -1px;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  h3 {
    max-width: 80vw;
    margin-bottom: 28px;
  }
  p {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    word-break: keep-all;
  }
}
</style>
