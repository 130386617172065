<template>
  <header id="header">
    <a href="#" class="logo">
      <picture>
        <source
          srcset="@/assets/images/logo_mo.svg"
          media="(max-width: 600px)"
        />
        <img src="@/assets/images/logo.svg" alt="logo_image" />
      </picture>
    </a>
    <button class="menu_btn" @click="isActive = !isActive">
      <img src="@/assets/images/ico_menu.svg" alt="menu-icon" />
    </button>
    <ul :class="{ active: isActive }">
      <button class="close_btn" v-show="isActive" @click="isActive = false">
        <img src="@/assets/images/ico_close.svg" alt="close-icon" />
      </button>
      <li @click="handleScroll(0, 'home')">
        <a><span :class="{ select: isSelected[0] }">홈</span></a>
      </li>
      <li @click="handleScroll(1, 'intro')">
        <a><span :class="{ select: isSelected[1] }">소개</span></a>
      </li>
      <li @click="handleScroll(1, 'education')">
        <a><span :class="{ select: isSelected[2] }">교육안내</span></a>
      </li>
      <li @click="handleScroll(3, 'program')">
        <a><span :class="{ select: isSelected[3] }">민간자격</span></a>
      </li>
      <li @click="handleScroll(4, '#')">
        <a><img src="@/assets/images/ico_insta.svg" alt="instagram_icon" /></a>
      </li>
    </ul>
  </header>
</template>

<script setup>
import { ref } from "vue";
const isActive = ref(false);
const isSelected = ref([false, false, false, false]);

const handleScroll = (i, name) => {
  // scroll
  const target = document.getElementById(name);
  let offsetY = 127;
  window.scrollTo({
    top: name === "program" ? target?.offsetTop : target?.offsetTop - offsetY,
    behavior: "smooth",
  });

  // mo - bg style
  isActive.value = false;
  if (isSelected.value.indexOf(true) > -1)
    isSelected.value = [false, false, false, false];
  isSelected.value[i] = !isSelected.value[i];
};
</script>

<style lang="scss" scoped>
#header {
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 50;
}
.logo {
  margin: 4% 5.8% 0;
}
ul {
  padding: 36px 40px 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 28px;
}
a {
  font-size: 28px;
  color: #ffffff;
  cursor: pointer;
  letter-spacing: -2px;
}
span {
  &::after {
    content: "";
    display: block;
    opacity: 0;
    transform: translateZ(0);
    width: 0;
    height: 4px;
    margin: -4px 0 0 2px;
    background-color: #a0e494;
    transition: all 0.5s ease;
  }
  &:hover {
    &::after {
      opacity: 1;
      width: 100%;
      transform: translateX(0);
    }
  }
}
.menu_btn {
  display: none;
}

@media (max-width: 1023px) {
  .logo {
    margin: 35px 0 0 35px;
  }
  a {
    font-size: 22px;
  }
  button {
    width: 34px;
    height: 34px;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  #header {
    display: block;
    background: #faf7f4;
    margin: 0;
    padding: 24px 0;
  }
  .logo {
    margin: 0;
    img {
      margin: 0 auto;
    }
  }
  ul {
    display: none;
    &.active {
      display: block;
      width: calc(100vw - 27px);
      height: 100vh;
      background: #ffffff;
      flex-direction: column;
      padding: 92px 28px 48px;
      position: absolute;
      top: 0;
      right: 0;
      animation: slideRightToLeft 0.8s cubic-bezier(0.19, 1, 0.22, 1) forwards;
      &::after {
        content: "";
        display: inline-block;
        width: 358px;
        height: 356px;
        position: absolute;
        bottom: -20px;
        right: 10px;
        background: url("@/assets/images/menu_bg.png") no-repeat right bottom /
          contain;
      }
      li {
        &:last-child {
          filter: invert(1);
          margin-top: auto;
        }
      }
    }
  }
  li {
    width: 100vw;
    padding: 7px 0;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
      position: absolute;
      left: 28px;
      bottom: 48px;
    }
  }
  a {
    display: block;
    width: inherit;
    font-size: 28px;
    color: #252525;
  }
  button {
    width: 40px;
    height: 40px;
  }
  span {
    text-align: center;
    &::after {
      display: none;
    }
    &:hover {
      &::after {
        display: none;
      }
    }
    &.select {
      padding: 5px;
      background: #a0e494;
    }
  }
  .menu_btn {
    display: block;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
  .close_btn {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 30px;
    right: 27px;
  }
}

@keyframes slideRightToLeft {
  0% {
    opacity: 0;
    transform: translateX(200vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
