<template>
  <ul class="card_list justify-center">
    <li
      class="card"
      data-aos-anchor="#education"
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-easing="ease-in-cubic"
      data-aos-delay="800"
      data-aos-duration="1000"
      data-aos-once="false"
    >
      <img src="@/assets/images/education01.png" alt="section3-image" />
      <h4>인성차문화 지도사범 교육</h4>
      <p>인성차문화예절지도사로서 갖추어야</p>
      <p>할 덕목 및 우리 예절 등 차 관련</p>
      <p>제반 분야 교육</p>
    </li>
    <li
      class="card"
      data-aos-anchor="#education"
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-easing="ease-in-cubic"
      data-aos-delay="1200"
      data-aos-duration="1000"
      data-aos-once="false"
    >
      <img src="@/assets/images/education02.png" alt="section3-image" />
      <h4>인성교육</h4>
      <p>핵심가지와 8가지 인성덕목에 관한</p>
      <p>프로그램 개발·보급</p>
    </li>
    <li
      class="card"
      data-aos-anchor="#education"
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-easing="ease-in-cubic"
      data-aos-delay="1600"
      data-aos-duration="1000"
      data-aos-once="false"
    >
      <img src="@/assets/images/education03.png" alt="section3-image" />
      <h4>문화체험</h4>
      <p>다양하고 재미있는 취미 활동으로</p>
      <p>역량을 키워본다.</p>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.card_list {
  display: flex;
  gap: 40px;
  padding-bottom: 80px;
  border-bottom: 1px solid #cbcbcb;
}
.card {
  background-color: #ffffff;
}
h4 {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -1.5px;
  padding-bottom: 24px;
  padding: 40px;
}
p {
  padding: 0 40px;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -1px;
  &:last-child {
    padding-bottom: 40px;
  }
}

@media (max-width: 1023px) {
  .card_list {
    flex-direction: column;
    align-items: center;
    gap: 60px;
    border: none;
  }
  .card {
    // min-width: 320px;
    width: calc(100vw - 30px);
    position: relative;
    &:last-child {
      &::after {
        content: "";
        // min-width: 400px;
        width: calc(100vw - 30px);
        height: 1px;
        background-color: #cbcbcb;
        display: block;
        position: absolute;
        bottom: -80px;
      }
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  h4 {
    padding: 30px;
    font-size: 24px;
    padding-bottom: 18px;
  }
  p {
    padding: 0 30px;
    font-size: 18px;
    line-height: 28px;
    &:last-child {
      padding-bottom: 30px;
    }
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .card_list {
    padding: 0 16px;
  }
  .card {
    &:last-child {
      &::after {
        min-width: 100vw;
        bottom: -80px;
      }
    }
  }
  h4 {
    font-size: 20px;
    padding-bottom: 12px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
