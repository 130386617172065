<template>
  <footer id="footer">
    <div class="footer_box">
      <div class="logo">
        <picture>
          <source
            srcset="@/assets/images/logo_footer_mo.svg"
            media="(max-width: 600px)"
          />
          <img src="@/assets/images/logo_footer.svg" alt="logo_image" />
        </picture>
      </div>
      <ul class="address">
        <li>마운 다미원 577-73-00519 대표 : 김경자</li>
        <li>주소 : 전라남도 담양군 대전면 서옥화암길 9</li>
        <li>고객센터 : 010. 2644. 4197</li>
        <li>이메일 : wldud9936@naver.com</li>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
#footer {
  max-width: 100vw;
  height: 188px;
  background-color: #ece8e4;
  padding: 45.5px 0;
}
.footer_box {
  display: flex;
  align-items: flex-start;
  max-width: 1280px;
  margin: 0 auto;
}
li {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  margin-left: 48px;
  &:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1023px) {
  #footer {
    min-width: 320px;
    height: 150px;
    padding: 30px;
  }
  li {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    margin-left: 35px;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  #footer {
    min-height: 407px;
    padding: 40px 16px;
  }
  .footer_box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: center;
  }
  .logo {
    margin-bottom: 48px;
  }
  li {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.8px;
    margin: 0 0 10px 0;
    text-align: center;
  }
}
</style>
