<template>
  <div id="education">
    <SubTitle :txt="'교육안내'" />
    <!-- pc / tablet -->
    <Card v-if="!props.mobile" />
    <!-- mo -->
    <Slide v-else />  
  </div>
</template>

<script setup>
import SubTitle from '../components/sub-title.vue'
import Card from '../components/card.vue'
import Slide from '../components/slide.vue'
import { defineProps } from 'vue';

const props = defineProps({ mobile: Boolean })
</script>

<style lang="scss" scoped>
#education{
  width: 1280px; 
  max-height: max-content;
  margin: 0 auto;
}
h3{
  text-align: center;
  padding: 120px 0 80px;
}

@media (max-width:1023px) { 
  #education{
    width: 100vw;
  }
}
</style>