<template>
  <div class="slide_wrap">
    <ul class="slide_box d_flex_center">
      <li class="slide">
        <img src="@/assets/images/education01_mo.png" alt="section3-image" />
        <h4>인성차문화 지도사범 교육</h4>
        <p>인성차문화예절지도사로서 갖추어야</p>
        <p>할 덕목 및 우리 예절 등 차 관련</p>
        <p>제반 분야 교육</p>
      </li>
      <li class="slide">
        <img src="@/assets/images/education02_mo.png" alt="section3-image" />
        <h4>인성교육</h4>
        <p>핵심가지와 8가지 인성덕목에 관한</p>
        <p>프로그램 개발·보급</p>
      </li>
      <li class="slide">
        <img src="@/assets/images/education03.png" alt="section3-image" />
        <h4>문화체험</h4>
        <p>다양하고 재미있는 취미 활동으로</p>
        <p>역량을 키워본다.</p>
      </li>
    </ul>

    <!-- indicator -->
    <div class="indicator d_flex_center">
      <button v-for="(item, i) in btnList" :key="i" :class="{on: item}" @click="handleClick(i)"></button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnList: [false, false, false],
      slideBox: null,
      touchStartX: 0,
      touchEndX: 0
    };
  },
  mounted() {
    this.slideBox = document.querySelector('.slide_box');
    this.btnList[0] = true;
    this.setupTouchEvents();
  },
  methods: {
    setupTouchEvents() {
      this.slideBox.addEventListener("touchstart", this.handleTouchStart);
      this.slideBox.addEventListener("touchend", this.handleTouchEnd);
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].pageX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].pageX;
      const translateX = this.slideBox.style.transform || "";
      
      if (this.touchStartX < this.touchEndX) {
        if (translateX === `translateX(-${window.outerWidth}px)`) {
          this.handleClick(0);
        } else if (translateX === `translateX(-${window.outerWidth * 2}px)`) {
          this.handleClick(1);
        }
      } else if (this.touchStartX > this.touchEndX) {
        if (translateX === "" || translateX === "translateX(0px)") {
          this.handleClick(1);
        } else if (translateX === `translateX(-${window.outerWidth}px)`) {
          this.handleClick(2);
        }
      }
    },
    handleClick(n) {
      this.btnList = this.btnList.map((value, index) => index === n);
      this.slideBox.style.transform = `translateX(-${window.outerWidth * n}px)`;
    }
  }
};
</script>

<style lang="scss" scoped>
  .slide_wrap{
    position: relative;
    width: max-content;
    height: max-content;
    user-select: none;
  }
  .d_flex_center{
    gap: 32px;
  }
  .slide_box{
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 16px;
  }
  .slide{
    width: calc(100vw - 32px);
    height: 654px;
    position: relative;
    background-color: #ffffff;
    img{
      width: 100%;
      height: 480px;
      object-fit: cover;
    }
    &:after{
      content: '';
      display: block;
      background: #CBCBCB;
      width: calc(100vw - 32px);
      height: 1px;
      position: absolute;
      bottom: -116px;
    }
    .show{
      transform: translateX(400px);
    }
  }
  h4{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -1px;
    padding: 32px 0 24px 20px;
  }
  p{
    padding: 0 20px;
    font-size: 16px;  
    line-height: 24px;
    letter-spacing: -1px;
    &:last-child{
      padding-bottom: 32px;
    }
  }
  .indicator{
    gap: 16px;
    flex-direction: row;
    width: 170px;
    margin-top: 40px;
    position: absolute;
    left: 50vw;
    transform: translateX(-50%);
    button{
      position: relative;
      width: 45px;
      height: 36px;
      background: url('@/assets/images/ico_leaf.svg') no-repeat center / contain;
      filter: invert(1);
      &.on{
        filter: invert(0);
        background: url('@/assets/images/ico_leaf_active.svg') no-repeat center / contain;
      }
    }
  }
</style>