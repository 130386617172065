<template>
  <div id="program">
    <!-- pc -->
    <template v-if="!props.mobile">
      <div>
        <div class="d_flex_center">
          <!-- txt -->
          <TxtList
            :list="list[0]"
            data-aos-anchor="#program"
            data-aos="fade-right"
            data-aos-easing="ease-in-sine"
            data-aos-delay="800"
            data-aos-duration="1200"
            data-aos-once="false"
          />
          <!-- icon -->
          <div
            class="ico_flower"
            data-aos-anchor="#program"
            data-aos="fade-down"
            data-aos-easing="ease-in-sine"
            data-aos-delay="1600"
            data-aos-duration="1000"
            data-aos-once="false"
          >
            <div class="ico leaf">
              <img
                src="@/assets/images/ico_leaf_active.svg"
                alt="flower-icon"
              />
            </div>
            <div class="ico flower">
              <img src="@/assets/images/ico_flower.svg" alt="flower-icon" />
            </div>
          </div>
          <!-- img -->
          <div
            class="img_wrap"
            data-aos-anchor="#program"
            data-aos="fade-left"
            data-aos-easing="ease-in-sine"
            data-aos-delay="1300"
            data-aos-duration="1200"
            data-aos-once="false"
          >
            <img src="@/assets/images/section3_img.png" alt="section4-image" />
          </div>
        </div>

        <div class="d_flex_center reverse">
          <!-- txt -->
          <TxtList
            :list="list[1]"
            data-aos-anchor="#program"
            data-aos="fade-left"
            data-aos-easing="ease-in-sine"
            data-aos-delay="2700"
            data-aos-duration="1200"
            data-aos-once="false"
          />
          <!-- icon -->
          <div
            class="ico_teapot"
            data-aos-anchor="#program"
            data-aos="fade-down"
            data-aos-easing="ease-in-sine"
            data-aos-delay="3000"
            data-aos-duration="1000"
            data-aos-once="false"
          >
            <div class="ico cup">
              <img src="@/assets/images/ico_teapot.svg" alt="teapot-icon" />
            </div>
            <div class="ico pot">
              <img src="@/assets/images/ico_pot.svg" alt="teapot-icon" />
            </div>
          </div>
          <!-- img -->
          <div
            class="img_wrap"
            data-aos-anchor="#program"
            data-aos="fade-right"
            data-aos-easing="ease-in-sine"
            data-aos-delay="2200"
            data-aos-duration="1200"
            data-aos-once="false"
          >
            <img
              src="@/assets/images/section3-2_img.png"
              alt="section4-image"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- mo -->
    <template v-else>
      <div>
        <div class="d_flex_center">
          <TxtList :list="list[0]" />
          <div class="flower_ico">
            <img src="@/assets/images/ico_flower_mo.svg" alt="flower-icon" />
          </div>
          <div class="img_wrap">
            <img
              src="@/assets/images/section3_img_mo.png"
              alt="section4-image"
            />
          </div>
        </div>
        <div class="d_flex_center">
          <TxtList :list="list[1]" />
          <div class="teapot_ico">
            <img src="@/assets/images/ico_teapot_mo.svg" alt="teapot-icon" />
          </div>
          <div class="img_wrap">
            <img
              src="@/assets/images/section3-2_img_mo.png"
              alt="section4-image"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import TxtList from "../components/txt-list.vue";
import { ref, defineProps } from "vue";

const list = ref([
  {
    title: "오감만족 힐링 프로그램",
    p1: "오감만족 힐링 프로그램",
    p2: "홍차티 마스터, 중국차 티마스터",
    p3: "원데이 클래스, 육우의 다경 특강, 인문학 강좌, 티파티,",
    p4: "제다실습 및 차문화 기행, 차문화 공방마켓 등",
    p5: "다양한 오감만족 힐링 프로그램",
  },
  {
    title: "민간자격",
    p1: "인성차문화예절지도사, 인성교육예절지도사,",
    p2: "청소년·어린이인성예절지도사 및 티마스터 교육 등",
    p3: "다양한 교육과정",
  },
]);

const props = defineProps({ mobile: Boolean });
</script>

<style lang="scss" scoped>
#program {
  width: 1280px;
  max-height: 1300px;
  margin: 0 auto;
  padding: 80px 0 200px;
}
.d_flex_center ~ .d_flex_center {
  margin-top: 80px;
}
.reverse {
  flex-direction: row-reverse;
}

[class*="ico"] {
  position: absolute;
}
.ico_flower {
  width: 130px;
  height: 122px;
  bottom: 26px;
  left: 480px;
}
.leaf {
  top: 0;
  right: 0;
  animation: shakeLeaf 7s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}
.flower {
  bottom: 0;
  left: 0;
}

.ico_teapot {
  width: 212px;
  height: 183px;
  right: 45px;
  bottom: 0;
}
.cup {
  bottom: 0;
  left: 0;
}
.pot {
  top: 0;
  right: 0;
  animation: moveTeapot 7s ease forwards infinite;
}

// leaf animation
@keyframes shakeLeaf {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
// pot animation
@keyframes moveTeapot {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(2deg);
    transform: translateY(0) rotate(2deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-10px) rotate(-20deg);
    transform: translateY(-10px) rotate(-20deg);
  }
  30% {
    -webkit-transform: translateY(10px) rotate(2deg);
    transform: translateY(10px) rotate(2deg);
  }
  45% {
    -webkit-transform: translateY(-20px) rotate(-25deg);
    transform: translateY(-20px) rotate(-25deg);
  }
  60% {
    -webkit-transform: translateY(10px) rotate(2deg);
    transform: translateY(10px) rotate(2deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(-10deg);
    transform: translateY(-6px) rotate(-10deg);
  }
}

@media (max-width: 1023px) {
  #program {
    width: 100vw;
    max-height: max-content;
    padding-bottom: 120px;
  }

  .d_flex_center {
    width: calc(100vw - 30px);
    margin: 0 auto;
  }
  .reverse {
    flex-direction: column;
  }
  .flex1 {
    width: 100%;
    padding-left: 30px;
  }
  .img_wrap {
    width: 100%;
    height: 470px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ico_flower {
    width: 120px;
    height: 112px;
    bottom: 530px;
    left: auto;
    right: 30px;
  }
  .flower {
    width: 95px;
    height: auto;
  }
  .leaf {
    width: 42px;
    height: auto;
  }

  .ico_teapot {
    width: 192px;
    height: 160px;
    bottom: 510px;
  }
  .cup {
    width: 175px;
    height: auto;
  }
  .pot {
    width: 155px;
    height: auto;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  #program {
    padding-top: 180px;
    padding-bottom: 60px;
  }
  .d_flex_center {
    flex-direction: column-reverse;
    gap: 40px;
    min-height: 764px;
  }
  .d_flex_center ~ .d_flex_center {
    margin-top: 50px;
  }
  .flex1 {
    width: 100%;
    padding-left: 0;
  }
  [class$="_ico"] {
    width: auto;
    animation: none;
  }
  .flower_ico {
    bottom: -20px;
    right: 7px;
  }
  .teapot_ico {
    bottom: 40px;
    right: 10px;
  }
}
</style>
