<template>
  <div id="wrap" ref="wrap">
    <Header />
    <Home />
    <Intro :mobile="isMobile" />
    <Education :mobile="isMobile" />
    <Program :mobile="isMobile" />
    <Footer />
  </div>
</template>

<script>
import Header from "./pages/components/header.vue";
import Footer from "./pages/components/footer.vue";
import Home from "./pages/section/home.vue";
import Intro from "./pages/section/intro.vue";
import Education from "./pages/section/education.vue";
import Program from "./pages/section/program.vue";

export default {
  components: { Header, Footer, Home, Intro, Education, Program },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    const wrap = this.$refs.wrap;

    // 해상도 변경 감지 후 모바일 class 추가
    if (wrap.getBoundingClientRect().width < 601) this.isMobile = true;
    // 이벤트 발생마다 감지
    window.addEventListener("resize", function () {
      if (wrap.getBoundingClientRect().width < 601) this.isMobile = true;
      window.location.reload();
    });

    window.addEventListener("scroll", () => {
      const intro = wrap.querySelector("#intro h2 span");

      if (window.scrollY > 200) {
        intro?.classList?.add("green_bg");
      } else {
        intro?.classList?.remove("green_bg");
      }
    });
  },
  unmounted() {
    window.removeEventListener("scroll");
  },
};
</script>

<style lang="scss">
#app {
  background-color: #faf7f4;
}
#wrap {
  margin: 0 auto;
  overflow: hidden;
}
.d_flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  position: relative;
}
.flex1 {
  flex: 1;
}

@media (max-width: 1023px) {
  #wrap {
    max-width: 100vw;
  }
  .d_flex_center {
    flex-direction: column;
    gap: 60px;
  }
}
</style>
