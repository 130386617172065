<template>
  <h3>
    {{ props.txt }}
  </h3>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps({ txt: String });
</script>

<style lang="scss" scoped>
h3 {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -1px;
  word-break: keep-all;

  @media (max-width: 1023px) {
    font-size: 28px;
    line-height: 37px;
    letter-spacing: 0;
  }

  @media (min-width: 320px) and (max-width: 600px) {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -1px;
  }
}
</style>
